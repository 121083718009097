import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Navbar from "../shared/Navbar";
// import Sidebar from '../shared/Sidebar';
import AppInfoservice from "../../service/common/Appinfo";
import UserStore from "../../store/UserStore";
import companyName from "../../store/mobxStore/companyName";
import style from "../components/dashboard/dashboard.module.scss";
import {
  logout,
  changTheme,
  addLang,
  addCurrency,
  addMenus,
  changeCurrStatus,
  changeLang,
  addMail,
  changePlan,
  addUsername,
  addNotify,
  changeCurr,
  addLogoutTime,
} from "../../store/action";
import Spinner from "../shared/Spinner";
import Sidebar from "../shared/Sidebar";
import video from "../../assets/images/anju.mp4";

import IdleTimeOutHandler from "../view/autologout/IdleTimeOutHandler";
// const Sidebar     = lazy(() => import('../shared/Sidebar'));

const mapStateToProps = (state) => {
  console.log("aleena", state);
  return {
    theme: state.Theme.currentTheme,
    Curr: state.curr,
    time: state.time.time,
    status: state.time.status,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      logout: bindActionCreators(logout, dispatch),
      addLang: bindActionCreators(addLang, dispatch),
      addCurrency: bindActionCreators(addCurrency, dispatch),
      addMenus: bindActionCreators(addMenus, dispatch),
      changeCurrStatus: bindActionCreators(changeCurrStatus, dispatch),
      changeLang: bindActionCreators(changeLang, dispatch),
      addMail: bindActionCreators(addMail, dispatch),
      changePlan: bindActionCreators(changePlan, dispatch),
      addUsername: bindActionCreators(addUsername, dispatch),
      addNotify: bindActionCreators(addNotify, dispatch),
      changTheme: bindActionCreators(changTheme, dispatch),
      changeCurr: bindActionCreators(changeCurr, dispatch),
      addLogoutTime: bindActionCreators(addLogoutTime, dispatch),
    },
  };
}
class MainLayout extends PureComponent {
  constructor(props) {
    super(props);
    this.AppInfoservice = new AppInfoservice();
    this.state = {
      loaders: true,
      userName: "",
      image: "",
      unapproved: localStorage.getItem("approved"),
    };
  }
  async componentDidMount() {
    console.log(
      "=====================================",
      localStorage.getItem("approved")
    );
    if (this.props.theme === "theme-dark") {
      const body = document.querySelector("body");
      body.classList.remove("theme-light");
      body.classList.remove("left-side-style");
      body.classList.add("theme-dark");
    } else if (this.props.theme === "left-side-style") {
      var element = document.getElementById("myid");
      const body = document.querySelector("body");
      body.classList.remove("theme-light");
      body.classList.remove("theme-dark");
      body.classList.remove("theme-light");
      body.classList.remove("theme-dark");
      body.classList.add("left-side-style");
    } else {
      var element = document.getElementById("myid");
      const body = document.querySelector("body");
      body.classList.remove("left-side-style");
      body.classList.remove("theme-dark");
      body.classList.remove("theme-dark");
      body.classList.remove("left-side-style");
      body.classList.add("theme-light");
    }
    let tockecken = {
      token: UserStore.key,
    };
    this.AppInfoservice.check_token(tockecken).then(async (res) => {
      console.log("i am 1", this.state.unapproved);
      if (!res.status) {
        this.props.history.push("/logout");
      } else if (this.state.unapproved == "true") {
        await this.AppInfoservice.get_layout().then((res) => {
          if (res.status) {
            this.setState({
              loaders: false,
              userName: res.data.user_name,
              image: res.data.user_Image,
            });
            this.props.actions.addMenus(res.data.menu_list);
            this.hoverOpen();
            this.props.actions.changeCurrStatus(res.data.currency_status);
            if (res.data.lang_status) {
              this.props.actions.addLang(res.data.languages);
              res.data.languages.forEach((element) => {
                if (element.default) {
                  console.log(
                    `language api called ${JSON.stringify(
                      element
                    )} code goes here ${element.code}`
                  );
                  this.props.actions.changeLang(element.code);
                  changeLang(element.code);
                }
              });
            } else {
              this.props.actions.addLang();
            }
            if (res.data.currency_status) {
              this.props.actions.addCurrency(res.data.currencies);
              if (localStorage.getItem("currency")) {
                let currencyData = this.props.Curr.Currecy.find(
                  (obj) => obj.code == localStorage.getItem("currency")
                );
                this.props.actions.changeCurr(
                  currencyData.symbol_left,
                  currencyData.value,
                  currencyData.precision,
                  currencyData.id
                );
              }
            }
            this.props.actions.addLogoutTime(
              res.data.logout_time * 1000,
              res.data.logout_status
            );
            this.props.actions.changePlan(res.data.mlm_plan);
            this.props.actions.addUsername(res.data.user_name);
            companyName.companyName = res.data.company_name;
            localStorage.setItem("width", res.data.width);
            localStorage.setItem("logouttime", res.data.logout_time * 1000);
          }
        });
      }
    });
    if (this.state.unapproved == "true") {
      this.AppInfoservice.getNotification().then((res) => {
        if (res.status) {
          this.props.actions.addMail(
            res.data.mail_details.mail_count,
            res.data.mail_details.mail_data
          );
          this.props.actions.addNotify(res.data.notification_details);
        } else {
          this.props.history.push("/logout");
        }
      });
    }
  }
  setThemelight(value) {
    var element = document.getElementById("myid");
    const body = document.querySelector("body");
    body.classList.remove("left-side-style");
    body.classList.remove("theme-dark");
    element.classList.remove("theme-dark");
    element.classList.remove("left-side-style");
    element.classList.add(value);
    // console.log(element)
  }
  setThemedark(value) {
    const body = document.querySelector("body");
    body.classList.remove("theme-light");
    body.classList.remove("left-side-style");
    body.classList.add(value);
    // console.log(element)
  }
  setThemelft(value) {
    var element = document.getElementById("myid");
    const body = document.querySelector("body");
    body.classList.remove("theme-light");
    body.classList.remove("theme-dark");
    element.classList.remove("theme-light");
    element.classList.remove("theme-dark");

    element.classList.add(value);
    //console.log(element)
  }

  notification = (id) => {
    this.AppInfoservice.notificationClick(id).then((res) => {
      if (res.status) {
        this.AppInfoservice.getNotification().then((res) => {
          if (res.status) {
            // this.props.actions.addMail(
            //   res.data.mail_details.mail_count,
            //   res.data.mail_details.mail_data
            // );
            this.props.actions.addNotify(res.data.notification_details);
          } else {
            this.props.history.push("/logout");
          }
        });
      } else {
      }
    });
  };

  hoverOpen() {
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
  render() {
    // if (this.state.loaders) {
    //     return <Spinner />
    // }

    return (
      !this.state.loaders && (
        <div className={style.main_contant_sec} id="myid">
          {console.log("MainLAyout")}
          <div className="container-scroller">
            <Navbar {...this.state} notificationClick={this.notification} />
            <div className="container-fluid page-body-wrapper">
              <Sidebar loaders={this.state.loaders} />
              {this.props.status && (
                <IdleTimeOutHandler time={this.props.time} />
              )}
              <div className="main-panel">
                {/* <video id="background-video"  muted loop="true" autoplay="autoplay">
<source src={video} type="video/mp4"/>
</video>
               */}
                <div className="content-wrapper">
                  {this.props.children}
                  {/* { SettingsPanelComponent } */}
                </div>
                {/* { footerComponent } */}
              </div>
            </div>
          </div>
          {/* <div class="theme_changer_view">
            <div className="themechange_view_setting">
              <i class="fa fa-cog"></i>
            </div>
            <div class="themechange_view_area">
              <div
                onClick={() => {
                  this.setThemelight("theme-light");
                  this.props.actions.changTheme("theme-light");
                }}
                class="themechange_view_boxs"
              ></div>
              <div
                onClick={() => {
                  this.setThemelft("left-side-style");
                  this.props.actions.changTheme("left-side-style");
                }}
                class="themechange_view_boxs1 themechange_box1"
              ></div>
              <div
                onClick={() => {
                  this.setThemedark("theme-dark");
                  this.props.actions.changTheme("theme-dark");
                }}
                class="themechange_view_boxs2"
              ></div>
            </div>
          </div> */}
        </div>
      )
    );
  }
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(MainLayout))
);
