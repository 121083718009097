import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import companyName from "../../../../store/mobxStore/companyName";
import Forms from "../../../components/auth/Forms";
import ResetPasswordService from "../../../../service/Auth/ResetPassword";
import ResetButton from "../../../components/auth/ResetButton";
import { BASE } from "../../../../configuration/apiconfig";
import { Alert, Form } from "react-bootstrap";
import { AlertMessage } from "../../../components/common";
import { Container } from "react-bootstrap";
import style from "../../../style/App.module.scss";
import Brand from "../../../components/auth/Brand";
import { Language } from "../../../components/common/";
import { useParams, useHistory } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";
import { PasswordValidation } from "../../../hook/passwordValidation";

function ResetPassword({ t }) {
    var captchvalue;
    //  const recaptchaRef = React.createRef();
    const history = useHistory();
    const recaptchaRef = useRef(null);

    let loginContainer = {
        marginTop: "-1px",
        marginLeft: "23px",
        color: "#a94442",
        fontSize: "12px",
    };
    const service = new ResetPasswordService();

    const notifyInintialState = {
        show: false,
        message: "",
        type: "",
        header: "",
    };

    const [state, setState] = useState({
        newpassword: "",
        confpassword: "",
        captcha: "",
        captchvalue: "",
        isLoggedError: false,
        user_id: "",
        key: "",
        notify: notifyInintialState,
        captchaImage: "",
        FormError: {
            newpassword: "",
            confpassword: "",
            captcha: "",
        },
        btnDisabled: false,
    });
    const [show, setShow] = useState(false);
    const path = useParams();

    const formValid = (formError) => {
        let valid = true;
        Object.values(formError).forEach(
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    };

    onchange = (e) => {
        const service = new ResetPasswordService();
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
            FormError: {
                ...prevState.FormError,
                [name]: "",
            },
        }));
    };
    useEffect(() => {
        // const {state} = props.location;
        const { id } = path.resetkey;
        service.getuserName(path.resetkey).then((res) => {
            if (res.status) {
                setState((prevState) => ({
                    ...prevState,
                    user_id: res.data.user_id,
                    // key:res.data.key
                }));
                reloadCaptcha(res.data.user_id);
            } else {
                history.push("/login");
            }
        });
    }, []);
    const blurhandler = (name) => {
        if (name === "confpassword") {
            if (state.newpassword != state.confpassword) {
                setState((prevState) => ({
                    ...prevState,
                    FormError: {
                        ...prevState.FormError,
                        confpassword: "unmatch",
                    },
                }));
            }
        }
    };
    // const [
    //     validLength,
    //     hasNumber,
    //     upperCase,
    //     lowerCase,
    //     specialChar,
    //     mixedCase,
    // ] = PasswordValidation({
    //     password: password.password,
    //     policy: password.policy,
    // });
    // const [passwordPolicy, setPasswordPolicy] = useState({
    //     uppercase: upperCase,
    //     lowercase: lowerCase,
    //     sp_char: specialChar,
    //     number: hasNumber,
    //     min_length: validLength,
    //     mixedcase: mixedCase,
    // });
    // useEffect(() => {
    //     setPasswordPolicy({
    //         uppercase: upperCase,
    //         lowercase: lowerCase,
    //         sp_char: specialChar,
    //         number: hasNumber,
    //         min_length: validLength,
    //         mixedcase: mixedCase,
    //     });
    // }, [validLength, hasNumber, upperCase, lowerCase, specialChar, mixedCase]);

    const requiredValid = (value, name) => {
        const FormError = state.FormError;
        console.log(
            /[A-Z]/.test(value.length) && /[0-9]/.test(value.length),
            "=======================",
            /[A-Z]/.test(value),
            /[0-9]/.test(value)
        );
        switch (name) {
            case "newpassword":
                FormError.newpassword =
                    value.length === 0
                        ? "newpasswd"
                        : value.length < 16
                        ? "passwordLength16"
                        : !(/[A-Z]/.test(value) && /[0-9]/.test(value))
                        ? "shouldContainCapitalAndNumeric"
                        : "";
                break;
            case "confpassword":
                FormError.confpassword =
                    value.length === 0
                        ? "confpassword"
                        : value.length < 16
                        ? "passwordLength16"
                        : !(/[A-Z]/.test(value) && /[0-9]/.test(value))
                        ? "shouldContainCapitalAndNumeric"
                        : "";
                break;
            case "captcha":
                FormError.captcha = value.length === 0 ? "captchareq" : "";
                break;
            default:
                break;
        }

        setState((prevState) => ({
            ...prevState,
            FormError,
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault(); //prevent default
        requiredValid(state.newpassword, "newpassword");
        requiredValid(state.confpassword, "confpassword");
        //   requiredValid(state.captcha, 'captcha');
        if (formValid(state.FormError)) {
            const formData = {
                confirm_pass: state.confpassword,
                pass: state.newpassword,
                //captcha: state.captcha,
                user_id: state.user_id,
                // reset_password_submit:true,
                //  key:state.key
            };
            service.resetPassword(formData).then((res) => {
                if (res.status) {
                    showNotify(
                        "success",
                        "Common.success",
                        "validation.password_reset"
                    );
                    setTimeout(() => {
                        history.push("/login");
                    }, 5000);
                } else {
                    if (res.error.code == 1030) {
                        showNotify("danger", res.error.description);
                    }
                    showNotify(
                        "danger",
                        t("Common.error"),
                        t("validation.invalidCaptcha")
                    );
                }
            });
        }
    };

    const showNotify = (type, header, message) => {
        setState((prev) => ({
            ...prev,
            notify: {
                ...prev.notify,
                show: true,
                type: type,
                header: t(header),
                message: t(message),
            },
        }));
    };

    const reloadCaptcha = (userName) => {
        service.getCaptcha(userName).then((res) => {
            let outside = URL.createObjectURL(res);
            setState((prev) => ({
                ...prev,
                captchaImage: outside,
            }));
        });
    };

    const notifyDissmissed = () => {
        setState((prev) => ({
            ...prev,
            notify: notifyInintialState,
        }));
    };

    const CaptchaChage = (value) => {
        setState((prevState) => ({
            ...prevState,
            captcha: value,
            FormError: {
                ...prevState.FormError,
                captcha: "",
            },
        }));
    };

    return (
        <>
            <div className="mb-2">
                <Helmet>
                    <title>
                        {companyName.companyName} | {t("Common.resetPassword")}
                    </title>
                </Helmet>

                {state.notify.show && (
                    <AlertMessage
                        type={state.notify.type}
                        message={state.notify.message}
                        show={state.notify.show}
                        dismiss={notifyDissmissed}
                    />
                )}
                <div className={`${style.app} pt-5`}>
                    <Container className={style.loginContainer}>
                        <Brand />
                        <Language classname="mb-4 w-100" />
                        <form id="forgot-form">
                            <Forms
                                type="password"
                                name="newpassword"
                                change={onchange}
                                error={state.FormError.newpassword}
                                value={state.newpassword}
                            />
                            <Forms
                                type="password"
                                name="confpassword"
                                change={onchange}
                                error={state.FormError.confpassword}
                                value={state.confpassword}
                                blurhandler={blurhandler}
                                from="reset"
                            />
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                            />
                            {/* {state.captchaImage &&
                        <>
                            <React.Fragment>
                                <Form.Group style={{ textAlign: 'center' }}>
                                    <img src={state.captchaImage} alt="" />
                                </Form.Group>
                                <div style={{ textAlign: 'center' }}>

                                    <a style={{ verticalAlign: "middle", fontSize: '15px', float: 'right', cursor: 'pointer' }} onClick={() => reloadCaptcha(state.user_id)}>   {t('Common.changeCaptcha')} </a>
                                   
                                </div>
                                <Forms
                                    type="text"
                                    name="captcha"
                                    change={onchange}
                                    error={state.FormError.captcha}
                                />


                            </React.Fragment>

                        </>

                    } */}

                            <ResetButton submit={onSubmit} />
                        </form>
                    </Container>
                </div>
            </div>
        </>
    );
}
export default withTranslation()(ResetPassword);
