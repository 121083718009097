import React, { useState, useEffect } from "react";
import Tree from "react-d3-tree";
// import orgChartJson from "./data/org-chart3.json";
import { useCenteredTree } from "./helpers";
// import "./styles.css";

import { useHistory } from "react-router-dom";
import style from "../../components/network/network.module.scss";

import GenealogyService from "../../../service/tree/genealogy";
import { ConstructionOutlined } from "@mui/icons-material";
import Spinner from "../../shared/Spinner";

import { usePopperTooltip } from "react-popper-tooltip";
import { ToolTips } from "../../components/common";

import down from "../../../assets/icons/down.png";

import add_disabled from "../../../assets/icons/add_disabled.png";
import loader from "../../../assets/icons/loader.gif";
import add from "../../../assets/icons/add.jpg";
import nophoto from "../../../assets/images/nophoto/no_photo.jpg";

import SearchContent from "../../components/network/SearchContent";

import Helmet from "react-helmet";
import PageTitle from "../../components/common/pageTitle";
import companyName from "../../../store/mobxStore/companyName";

import { useTranslation, withTranslation } from "react-i18next";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";

import _ from "lodash";

const containerStyles = {
  width: "100vw",
  height: "100vh",
};

let value = "300";

// Here we're using `renderCustomNodeElement` to bind event handlers
// to the DOM nodes of our choice.
// In this case, we only want the node to toggle if the *label* is clicked.
// Additionally we've replaced the circle's `onClick` with a custom event,
// which differentiates between branch and leaf nodes.
const renderNodeWithCustomEvents = ({
  nodeDatum,
  toggleNode,
  handleNodeClick,
  addClick,
  tooltipHandler,
}) => (
  <g>
    {console.log("val", nodeDatum?.isLoader)}
    {nodeDatum?.attributes.count != 0 ? (
      nodeDatum?.attributes.depth == 3 ? (
        <g>
          {" "}
          <image
            x="-30"
            y="-25"
            style={{ width: "60px", height: "60px" }}
            href={
              nodeDatum.attributes.photo ? nodeDatum?.attributes.photo : nophoto
            }
            onMouseOver={() => tooltipHandler(nodeDatum, true)}
            onMouseOut={() => tooltipHandler(nodeDatum, false)}
            alt={nophoto}
          ></image>{" "}
          <image
            x="-30"
            y="-25"
            style={{ width: "60px", height: "60px" }}
            href={
              nodeDatum?.isLoader
                ? "https://discuss.wxpython.org/uploads/default/original/2X/6/6d0ec30d8b8f77ab999f765edd8866e8a97d59a3.gif"
                : ""
            }
            alt={nophoto}
          ></image>{" "}
        </g>
      ) : (
        <image
          x="-30"
          y="-25"
          style={{ width: "60px", height: "60px" }}
          href={
            nodeDatum.attributes.photo ? nodeDatum?.attributes.photo : nophoto
          }
          alt={nophoto}
          onMouseOver={() => tooltipHandler(nodeDatum, true)}
          onMouseOut={() => tooltipHandler(nodeDatum, false)}
        ></image>
      )
    ) : nodeDatum?.attributes.disable == 1 ? (
      <image
        x="-30"
        y="-25"
        style={{ width: "60px", height: "60px" }}
        href={add_disabled}
        alt="no image"
      ></image>
    ) : (
      <image
        x="-22"
        y="-25"
        style={{ width: "45px", height: "45px" }}
        href={add}
        alt="no image"
        onClick={() => addClick(nodeDatum)}
      ></image>
    )}
    {/* { !nodeDatum.children ?  <image  style={{ width: "50px" ,height : "50px" , x :"-25" ,  y :"-25"}}
          href="https://www.w3schools.com/howto/img_avatar.png"
          alt="no image" onClick={() => handleNodeClick(nodeDatum)} onDoubleClick={toggleNode}></image> : <image  style={{ width: "50px" ,height : "50px" , x :"-25" ,  y :"-25"}}
          href="https://upload.wikimedia.org/wikipedia/commons/f/f9/Phoenicopterus_ruber_in_S%C3%A3o_Paulo_Zoo.jpg"
          alt="no image" onClick={() => handleNodeClick(nodeDatum)} onDoubleClick={toggleNode}></image>} */}

    <filter x="0" y="0" width="1" height="1" id="solid">
      <feFlood flood-color="#eef8ff" result="bg" />

      <feMerge>
        <feMergeNode in="bg" />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
    <filter x="0" y="0" width="1" height="1" id="blocked">
      <feFlood flood-color="#ff0000" result="bg" />

      <feMerge>
        <feMergeNode in="bg" />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
    <g>
      {nodeDatum?.attributes.depth == 3 ? (
        <text
          fill="black"
          filter={"url(#solid)"}
          strokeWidth="1"
          x="-35"
          y="55"
          onClick={() => handleNodeClick(nodeDatum)}
        >
          {nodeDatum.name}
        </text>
      ) : (
        <text
          fill="black"
          filter={"url(#solid)"}
          strokeWidth="1"
          x="-35"
          y="55"
          onClick={toggleNode}
        >
          {nodeDatum.name}
        </text>
      )}
    </g>

    <g>
      {nodeDatum?.attributes.active == 1 ? (
        nodeDatum?.attributes.depth == 3 ? (
          <text
            fill="black"
            filter={"url(#solid)"}
            strokeWidth="1"
            x="-35"
            y="55"
            onClick={() => handleNodeClick(nodeDatum)}
          >
            {nodeDatum.name}
          </text>
        ) : (
          <text
            fill="black"
            filter={"url(#solid)"}
            strokeWidth="1"
            x="-35"
            y="55"
            onClick={toggleNode}
          >
            {nodeDatum.name}
          </text>
        )
      ) : nodeDatum?.attributes.depth == 3 ? (
        <text
          fill="black"
          filter={"url(#blocked)"}
          strokeWidth="1"
          x="-35"
          y="55"
          onClick={() => handleNodeClick(nodeDatum)}
        >
          {nodeDatum.name}
        </text>
      ) : (
        <text
          fill="black"
          filter={"url(#blocked)"}
          strokeWidth="1"
          x="-35"
          y="55"
          onClick={toggleNode}
        >
          {nodeDatum.name}
        </text>
      )}
    </g>
  </g>
);

export default function App(props) {
  const [state, setState] = useState({
    loading: false,
    data: "",
    data2: true,
    finaldata: [],
    tooltipConfig: {},
    storeUrl: "",
    xaxis: "30px",
    yaxis: "30px",
    nodeDatum: "",
    value: false,
    username: "",
    error: {
      show: false,
      message: "",
    },
    serachloading: false,
    resetloading: false,
  });
  const [error, setError] = useState(false);
  const history = useHistory();
  const service = new GenealogyService();
  const { t } = useTranslation();
  const username = useSelector((state) => state.ProjectConfig.userName);

  useEffect(() => {
    const { userName } = "";
    getlist(userName);
    // setState({
    // 	...state,
    // 	data2: orgChartJson
    // })
  }, []);

  const getlist = (username = "", parentId = "") => {
    if (state.data2) {
      service.getTreelist2(username, parentId).then((res) => {
        if (res.status) {
          setState((prevState) => ({
            ...prevState,
            loading: false,
            data: res.data,
            tooltipConfig: res.data.tooltip_config,
            storeUrl: res.data.store_url,
          }));
        } else {
          //if the invalid access tocken
          if (res.error?.code === 1002) {
            //	alert("logout")
            history.push("/mobileGenealogy");
          } else if (res.error.code === 1043) {
            //alert("error")
            setError(true);
            history.push({
              pathname: "/mobileGenealogy",
              state: {
                error: true,
                message: "InvalidUserName",
              },
            });
          }
        }
      });
    }
  };

  // const handleNodeClick = (nodeDatum) => {

  //  Object.entries(orgChartJson).map((key,value)=>{
  //   orgChartJson['children'].map((obj)=>{
  //     if(obj.children.find((data)=>(data.name==nodeDatum.name))){
  //       console.log("object",obj.children)

  //     }

  //   })

  //  })
  // };

  //reset button click
  const resetButtonClick = () => {
    setState((prev) => ({
      ...prev,
      resetloader: true,
    }));
    setError(false);
    history.push("/mobileGenealogy");
    getlist(username);
    setState((prev) => ({
      ...prev,
      username: username,
      resetloader: false,
    }));
    // setTimeout(() => {
    // setState(prev => ({
    // 	...prev,
    // 	username: username,
    // 	resetloader: false
    // }))

    // }, 3000);
  };

  //search button click
  const searchClick = (e) => {
    e.preventDefault();
    setState((prev) => ({
      ...prev,
      searchloader: true,
    }));
    if (state.username) {
      getlist(state.username);
    }
    setState((prev) => ({
      ...prev,
      searchloader: false,
    }));
    // setTimeout(() => {
    // 	setState(prev => ({
    // 		...prev,
    // 		searchloader: false
    // 	}))

    // }, 3000);
  };

  const change = (e) => {
    const { value } = e.target;
    setState((prev) => ({
      ...prev,
      username: value,
    }));
  };

  //click the down line members
  const clickDownLine = () => {
    history.push({
      pathname: "/downlineMembers",
      state: { prevPath: history.location.pathname },
    });
  };

  const getChildTreeData = (orgChartJson, nodeDatum, extraData) => {
    var finaldata = Object.assign({}, orgChartJson);
    // finaldata.children = []
    iterate(orgChartJson, nodeDatum, extraData, finaldata);
    setState((prev) => ({
      ...prev,
      data: finaldata,
      data2: false,
      loading: false,
    }));
  };
  const iterate = (orgChartJson, nodeDatum, extraData, finaldata) => {
    if (false) {
    } else {
      (orgChartJson?.children).forEach((element, key) => {
        // finaldata.children.push(element)
        if (element.attributes.user_id == nodeDatum.attributes.father_id) {
          var index = element.children.findIndex(
            (obj) => obj.name === nodeDatum.name
          );
          element.children.splice(index, 1, extraData);
        } else {
          iterate(element, nodeDatum, extraData, finaldata);
        }
      });
    }
    return true;
    // Object.keys(orgChartJson.children).forEach(key => {
    //   console.log(key, "sadsad")

    //   //    console.log(`key: ${key}, value: ${orgChartJson[key]}`)
    //   //  console.log(orgChartJson[key])
    //   if (orgChartJson['user_name'] == "INF718677") {
    //     console.log("sadasdgcdcccccccccccccc")
    //   }
    //   if (typeof orgChartJson[key] === 'object' && orgChartJson[key] !== null) {

    //     iterate(orgChartJson[key])
    //   }
    // })
  };

  const handleNodeClick = (nodeDatum) => {
    console.log("1", nodeDatum.isLoader);
    nodeDatum.isLoader = true;
    // setState((prev) => ({
    // 	...prev,
    // 	loading: true
    // }));
    //  getlist(nodeDatum.attributes.user_name);
    console.log("1", nodeDatum.isLoader);
    service.getTreelist2(nodeDatum.attributes.user_name).then((res) => {
      if (res.status) {
        nodeDatum.isLoader = false;
        getChildTreeData(state.data, nodeDatum, res.data);
      } else {
        nodeDatum.isLoader = false;
        //if the invalid access tocken
        if (res.error?.code === 1002) {
          // alert("logout")
          history.push("/mobileGenealogy");
        } else if (res.error.code === 1043) {
          // alert("error")
          history.push({
            pathname: "/mobileGenealogy",
            state: {
              error: true,
              message: "InvalidUserName",
            },
          });
        }
      }
    });
  };

  const tooltipContainer = (nodeDatum, x, y, value) => {
    console.log("valuees", nodeDatum, "ddddd", value);
    console.log("sada", x, y);
    // alert(x)
    return (
      value && (
        <div
          className={style.tooltipContainerNew}
          style={{
            position: "absolute",
            zIndex: "999",
            top: `${y - 71}px`,
            left: `${x - 200}px`,
          }}
        >
          <ToolTips
            style={{
              position: "absolute",
              zIndex: "999",
              top: `${y - 71}px`,
              left: `${x - 200}px`,
            }}
            config={state.tooltipConfig}
            {...nodeDatum.attributes}
          />
        </div>
      )
    );
  };

  const tooltipHandler = (nodeDatum, value) => {
    let x = window.event.clientX;
    let y = window.event.clientY;

    setState((prevState) => ({
      ...prevState,
      xaxis: x,
      yaxis: y,
      nodeDatum: nodeDatum,
      value: value,
    }));

    console.log("dsadsa", nodeDatum, "x", x, "y", y);

    tooltipContainer(nodeDatum, x, y, value);

    // 	<div
    // 	ref={setTooltipRef}
    // 	{...getTooltipProps({
    // 	  className: `tooltip-container `,
    // 	})}
    //   >
    // 	<div
    // 	  {...getArrowProps({ className: ` ${style.arrow}` })}
    // 	/>
    // 	<ToolTips config={props.toolTipConfig} {...props} />
    //   </div>
  };

  const addClick = (nodeDatum) => {
    console.log("vavavvavava", nodeDatum);
    if (nodeDatum.attributes.storeUrl) {
      window.open(
        `${nodeDatum.attributes.storeUrl}&position=${nodeDatum.attributes.leg}&username=${nodeDatum.attributes.user_name}&reg_from_tree=1`,
        "_blank"
      );
    } else {
      history.push(
        `/register/${nodeDatum.attributes.user_name}/${nodeDatum.attributes.leg}/true`
      );
    }
  };

  const [translate, containerRef] = useCenteredTree();
  return (
    <div>
      {/* <Helmet>
				<title>{companyName.companyName} | {t('Sidemenu.genealogyTree')}</title>
			</Helmet>
			<PageTitle title="genealogyTree" buttonOn={false} /> */}
      {error && history.location.state && (
        <Alert
          style={{ top: "5px" }}
          variant="danger"
          onClose={() => setError(false)}
          dismissible
        >
          {t("validation." + history.location.state.message)}
        </Alert>
      )}
      {state.loading ? (
        <Spinner />
      ) : (
        <div>
          {state?.data && (
            <div style={containerStyles} ref={containerRef}>
              <Tree
                nodeSize={{ x: 150, y: 150 }}
                data={state.data}
                pathFunc={"step"}
                // enableLegacyTransitions={true}
                translate={translate}
                onNodeMouseOut={(value) => {
                  console.log("i am on it", value);
                }}
                renderCustomNodeElement={(rd3tProps) =>
                  renderNodeWithCustomEvents({
                    ...rd3tProps,
                    handleNodeClick,
                    addClick,
                    tooltipHandler,
                  })
                }
                orientation="vertical"
              />
            </div>
          )}
          {/* <div style={{ position : "absolute", zIndex : "999", top: "300px", left: "90px" }}>hi how r u</div> */}
        </div>
      )}
      {tooltipContainer(state.nodeDatum, state.xaxis, state.yaxis, state.value)}
      <div
        className={style.MianContent}
        style={{
          position: "absolute",
          width: "100%",
          top: error ? "70px" : "20px",
        }}
      >
        {" "}
        <SearchContent
          reset={resetButtonClick}
          searchClick={searchClick}
          changeHandler={change}
          buttonClick={clickDownLine}
          buttonLabel="downlineMembers"
          {...state}
        />{" "}
      </div>
    </div>
  );
}
