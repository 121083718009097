import { React, useState, useEffect } from "react";
import style from "../../components/AcademicTutorials/academic.module.scss";
import {
    Card,
    Container,
    CardGroup,
    Button,
    Row,
    Col,
    Form,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import Spinner from "../../shared/Spinner";
import nodata from "../../../assets/images/nophoto/no-datas-found.png";
import { positions } from "@mui/system";
// import ReactPlayer from "react-player";
import Vimeo from "@u-wave/react-vimeo";
import AcademyService from "../../../service/Academy/Academy";
import { Link } from "react-router-dom";
import { Margin } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageTitle from "../../components/common/pageTitle";
import { AlertMessage } from "../../components/common";
import fileDownload from "js-file-download";
import axios from "axios";

function CourseDetails(props) {
    const { t } = useTranslation();
    const { currentTheme } = useSelector((state) => state.Theme);
    const currency = useSelector((state) => state.curr);
    const history = useHistory();
    const { id } = useParams();

    const [state, setState] = useState({
        data: [],
        pdfData: [],
        loader: true,
        count: "",
        video: [],
        pdfCount: 0,
        IsError: false,
        notifyMessage: "",
        header: "Error!",
        notyfiType: "",
    });
    const [pdf, setPdf] = useState(null);
    useEffect(() => {
        const service = new AcademyService();
        setState((prev) => ({
            ...prev,
            loader: true,
        }));
        service.getCourdeDetails(id).then((res) => {
            console.log(res);
            if (res.status) {
                setState({
                    ...state,
                    data: res.data,
                    pdfData: res.pdfData,
                    pdfCount: res.pdfCount,
                    //   data: res.data.questionArray,
                    //   video: res.data.video,
                    //   count: res.data.count,
                    loader: false,
                });
            } else {
            }
        });
    }, []);

    const download = (e) => {
        var fn = e.match(/([^/])+/g);
        fn = fn[fn.length - 1];
        console.log("******************************", e);
        try {
            fetch(e, { redirect: "follow" })
                .then((response) => {
                    if (response.status === 200 || response.status === 0) {
                        return response.blob().then((blob) => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement("a");
                            a.href = url;
                            a.download = fn;
                            a.click();
                        });
                    } else {
                        throw new Error("File download failed");
                    }
                })
                .catch((error) => {
                    // setState((prev) => ({
                    //   ...prev,
                    //   IsError: true,
                    //   notifyMessage: error.message,
                    // }));
                });
        } catch (error) {
            // setState((prev) => ({
            //   ...prev,
            //   IsError: true,
            //   notifyMessage: "File download failed",
            // }));
        }
    };
    const handleDownloadClick = (url) => {};
    const onProgress = (event) => {
        // console.log(event);
    };

    const notifyDissmissed = () => {
        setState((prev) => ({
            ...prev,
            IsError: false,
            notifyMessage: "",
        }));
    };

    //   const onclcikAnswer = (value, id) => {
    //     let data = state.data.map((obj, i) => {
    //       if (obj.questionId == id) {
    //         return {
    //           ...obj,
    //           answerType: value,
    //         };
    //       } else return obj;
    //     });
    //     setState({
    //       ...state,
    //       data: data,
    //     });
    //   };
    //   const showAnser = (qsId) => {
    //     let data = state.data.map((obj, i) => {
    //       if (obj.questionId == qsId) {
    //         if (obj.showAnser) {
    //           return {
    //             ...obj,
    //             answerType: obj.answerType,
    //             showAnser: false,
    //           };
    //         } else {
    //           return {
    //             ...obj,
    //             answerType: obj.correctAnswerid,
    //             showAnser: true,
    //           };
    //         }
    //       } else return obj;
    //     });
    //     setState({
    //       ...state,
    //       data: data,
    //     });
    //   };
    //   const submitAnswer = () => {
    //     const service = new Academyservice();
    //     let formData = [];
    //     state.data.map((obj, index) => {
    //       if (obj.answerType) {
    //         formData.push({ id: obj.questionId, opt: obj.answerType });
    //       }
    //     });
    //     service.postAnswers(formData).then((res) => {
    //       console.log(res);
    //     });
    //   };
    // const handleCardClick = (itemId) => {
    //   history.push(`/view-video/${itemId}`);
    // };
    console.log("videodataa", state.data);
    return (
        <>
            <PageTitle title="materials" buttonOn={false} />
            {state.loader ? (
                <Spinner />
            ) : (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div
                        className={`${style.downloadConatiner} vimeo`}
                        style={state.pdfCount !== 0 ? { flex: 9 } : {}}
                    >
                        <div
                            className={style.userDowmloadSection}
                            style={{ minHight: "80vh" }}
                        >
                            <Row>
                                {state && state.data.length > 0 ? (
                                    state.data.map((items, index) => (
                                        <Col sm={12} md={6} lg={4} key={index}>
                                            <Card
                                                // className="h-80 p-5 "
                                                style={{
                                                    marginTop: "5px",
                                                    marginBottom: "5px",
                                                    // height: "250px",
                                                }}
                                            >
                                                <Card.Body
                                                    style={{
                                                        padding: "8px",
                                                        width: "100%",
                                                        hieght: "100%",
                                                    }}
                                                >
                                                    <Link
                                                        to={`/view-video/${items.video_id}`}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                    "column",
                                                                alignItems:
                                                                    "center",
                                                                textAlign:
                                                                    "center",
                                                                hieght: "100%",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    items.thumbnail
                                                                }
                                                                alt="thumbnail"
                                                                style={{
                                                                    width: "100%",
                                                                    marginBottom:
                                                                        "10px",
                                                                    height: "100%",
                                                                }}
                                                            />

                                                            <Card.Title
                                                                style={{
                                                                    color: "#9a7c48",
                                                                    fontWeight:
                                                                        "bold",
                                                                    // fontSize: "15px",
                                                                    marginBottom:
                                                                        "3px",
                                                                    wordBreak:
                                                                        "break-all",
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                    overflow:
                                                                        "hidden",
                                                                    textOverflow:
                                                                        "ellipsis",
                                                                    maxWidth:
                                                                        "100%",
                                                                    padding:
                                                                        "0px 10px",
                                                                }}
                                                                // onClick={handleCardClick(items.video_id)}
                                                            >
                                                                {items.name}
                                                            </Card.Title>
                                                        </div>
                                                    </Link>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))
                                ) : (
                                    <>
                                        <div className={`${style.NodataImage}`}>
                                            <center>
                                                <img
                                                    src={nodata}
                                                    alt="nodata"
                                                    style={{ width: "50%" }}
                                                />
                                            </center>
                                        </div>
                                    </>
                                )}
                            </Row>
                        </div>
                    </div>
                    {state.pdfCount !== 0 && (
                        <div style={{ flex: 3, padding: "10px" }}>
                            {state && state.pdfData.length > 0 ? (
                                state.pdfData.map((items, index) => (
                                    <div
                                        style={{
                                            flexDirection: "row",
                                            padding: "5px",
                                            border: "solid 1px",
                                            borderRadius: "5px",
                                            margin: "5px",
                                            display: "grid",
                                            gridTemplateColumns: "1fr 5fr 1fr",
                                        }}
                                    >
                                        <div style={{ flex: "2" }}>
                                            <img
                                                src={items.thumbnail}
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            ></img>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    // alignSelf: "center",
                                                    fontWeight: "bold",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                {" "}
                                                {items.name}
                                            </span>
                                            <span
                                                style={{
                                                    // alignSelf: "center",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                {" "}
                                                {items.file_description}
                                            </span>
                                        </div>
                                        <button
                                            style={{
                                                backgroundColor: "#80602d ",
                                            }}
                                        >
                                            <a
                                                href={items.link}
                                                target="_blank"
                                                className={style.linkcoloor}
                                                download
                                                onClick={(e) =>
                                                    download(items.link)
                                                }
                                                rel="noreferrer"
                                            >
                                                <i
                                                    className="fa fa-download"
                                                    style={{ color: "#f19915" }}
                                                />
                                            </a>
                                        </button>
                                    </div>
                                ))
                            ) : (
                                <></>
                            )}
                        </div>
                    )}
                    {state.IsError && (
                        <AlertMessage
                            message={state.notifyMessage}
                            dismiss={notifyDissmissed}
                            type={state.notyfiType}
                            header={state.header}
                            show={state.IsError}
                        />
                    )}
                </div>
            )}
        </>
    );
}

export default CourseDetails;
