// //local
// import companyName from "../store/mobxStore/companyName";
// const COMPANY_NAME = companyName.companyName ?? "Company Name";
// const BASE_URL = "http://192.168.9.7:5004/api/web/";
// const IMG_URL = "http://192.168.9.7:5004/uploads/images/";
// const LEAD_URL = "http://192.168.9.7:5004/api/web/";
// const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// // const DEFAULT_KEY ='903's
// const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
// const BASE = "http://192.168.9.7:5004/";

// demo
// import companyName from "../store/mobxStore/companyName";
// const COMPANY_NAME = companyName.companyName ?? "Company Name";
// const BASE_URL = "http://162.19.146.134:1178/api/web/";
// const IMG_URL = "http://162.19.146.134:1178/uploads/images/";
// const LEAD_URL = "http://162.19.146.134:1178/api/web/";
// const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// // const DEFAULT_KEY ='903's
// const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
// const BASE = "http://162.19.146.134:1178/";

// live
import companyName from "../store/mobxStore/companyName";
const COMPANY_NAME = companyName.companyName ?? "Company Name";
const BASE_URL = "https://admin.mlm-wealthcode.at/api/web/";
const IMG_URL = "https://admin.mlm-wealthcode.at/api/uploads/images/";
const LEAD_URL = "https://admin.mlm-wealthcode.at/api/web/";
const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// const DEFAULT_KEY ='903's
const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
const BASE = "https://admin.mlm-wealthcode.at/";

export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE,
    DEFAULT_KEY,
    LEAD_URL,
};
