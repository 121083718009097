import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import style from "./registration.module.scss";
import { Paneltilte, Buttons } from "./";
import FormsControl from "./FormsControl";

function ContactInformation(props) {
  const [data, setData] = useState([]);
  //change handler
  const changehandler = (e) => {
    props.ChangeHandler(e, "contactInfo");
  };
  useEffect(() => {
    let newData = props.data.map((object) => {
      if (object.code === "gender") {
        let newOption = object.options.map((option) => {
          return {
            ...option,
            code: "profile." + option.code,
          };
        });
        return {
          ...object,
          options: newOption,
        };
      } else {
        return object;
      }
    });
    setData(newData);
  }, [props.data]);
  return (
    <div className={`${style.SponsorPackage}`}>
      <Paneltilte tilte="contactInformation" />
      <Form
        onSubmit={(e) => {
          props.submit(e, "", "contactInfo");
        }}
      >
        {data.map((field, index) => {
          return (
            <FormsControl
              key={index}
              data={field}
              change={changehandler}
              blurhandler={props.blurhandler}
              refs={props.refs}
              selectDate={props.selectDate}
            />
          );
        })}
        <Buttons prev={true} prevButtonClick={props.prevButtonClick} />
      </Form>
    </div>
  );
}

export default ContactInformation;
