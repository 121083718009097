import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { useTranslation, withTranslation } from "react-i18next";
// import {Redirect}           from 'react-router-dom';
import { Alert, Form } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { Link, withRouter, Redirect, useHistory } from "react-router-dom";
//components
import Forms from "../../../components/auth/Forms";
import LoginButton from "../../../components/auth/LoginButton";

//service
import LoginService from "../../../../service/Auth/Login";
import { islogged } from "../../../../store/action";
import UserStore from "../../../../store/UserStore";
import style from "../../../components/auth/auth.module.scss";
import { COMPANY_NAME } from "../../../../configuration/apiconfig";

import { useParams } from "react-router-dom";
import AppInfoservice from "../../../../service/common/Appinfo";
import { useDispatch, useSelector } from "react-redux";
import { changeCurr } from "../../../../store/action";
import ReCAPTCHA from "react-google-recaptcha";

const formValid = (formError) => {
    let valid = true;
    Object.values(formError).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
};
const mapStateToProps = (state) => {
    return { proPs: state };
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ islogged }, dispatch);
}

function Login(proPs) {
    const { currentTheme } = useSelector((state) => state.Theme);
    const Curr = useSelector((state) => state.curr);
    const params = useParams();
    const recaptchaRef = useRef(null);

    const [state, setState] = useState({
        userName: params.username ? params.username : "",
        password: params.username && params.adminusername ? "123456" : "",
        adminuser: params.adminusername ? params.adminusername : "",
        otp: "",
        timeout: localStorage.getItem("timeout")
            ? localStorage.getItem("timeout")
            : false,
        isLoggedError: false,
        isLogged: false,
        unapproved: false,
        FormError: {
            userName: "",
            password: "",
            adminuser: "",
            captchavalue: "",
            otp: "",
        },
        btnDisabled: false,
        loader: false,
        showCaptcha: false,
        captchavalue: "",
    });

    useEffect(() => {
        localStorage.setItem("adminname", params.adminusername);
        if (currentTheme === "theme-dark") {
            const body = document.querySelector("body");
            body.classList.remove("theme-light");
            body.classList.remove("left-side-style");
            body.classList.add("theme-dark");
        } else if (currentTheme === "left-side-style") {
            var element = document.getElementById("myid");
            const body = document.querySelector("body");
            body.classList.remove("theme-light");
            body.classList.remove("theme-dark");
            body.classList.remove("theme-light");
            body.classList.remove("theme-dark");
            body.classList.add("left-side-style");
        } else {
            var element = document.getElementById("myid");
            const body = document.querySelector("body");
            body.classList.remove("left-side-style");
            body.classList.remove("theme-dark");
            body.classList.remove("theme-dark");
            body.classList.remove("left-side-style");
            body.classList.add("theme-light");
        }
        setState((prevState) => ({
            ...prevState,
            timeout: localStorage.getItem("timeout")
                ? localStorage.getItem("timeout")
                : false,
        }));
    }, []);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [token, setToken] = useState();

    onchange = (e) => {
        e.preventDefault();
        const { name, value } = e.target; //get the field name
        setState((prevState) => ({
            ...prevState,
            [name]: value,
            FormError: {
                [name]: "",
            },
        }));
    };
    const resetform = () => {
        document.getElementById("login-form").reset(); //reset form
        setState((prevState) => ({
            //reset state
            ...prevState,
            userName: "",
            password: "",
        }));
    };

    const handleAlertDismiss = () => {
        setState((prevState) => ({
            ...prevState,
            isLoggedError: false,
            isOtpError: false,
        }));
    };
    const handleVerify = useCallback((token) => {
        setToken(token);
    });
    const requiredValid = (value, name) => {
        const FormError = state.FormError;
        switch (name) {
            case "userName":
                FormError.userName = value.length === 0 ? "required" : "";
                break;
            case "password":
                FormError.password = value.length === 0 ? "required" : "";
                break;
            case "adminuser":
                FormError.adminuser = value.length === 0 ? "required" : "";
                break;
            case "otp":
                FormError.otp = value.length === 0 ? "required" : "";
                break;
            default:
                break;
        }

        setState((prevState) => ({
            ...prevState,
            FormError,
        }));
    };

    const onOtpSubmit = async (e) => {
        e.preventDefault();
        requiredValid(state.otp, "otp");
        const service = new LoginService();
        if (formValid(state.FormError)) {
            setState((prevState) => ({
                ...prevState,
                loader: true,
            }));

            let formData = {};

            formData.username = state.userName;
            formData.auth_key = state.auth_key;
            formData.otp = state.otp;

            service.otpSend(formData).then((res) => {
                if (res.status) {
                    resetform();
                    localStorage.setItem(
                        "login",
                        JSON.stringify({
                            token: res.data.access_token,
                        })
                    );
                    localStorage.setItem("currency", res.data.currency.code);
                    localStorage.setItem("approved", res.data.approved);
                    UserStore.key = res.data.access_token;
                    UserStore.isLoggedIn = true;
                    setState((prevState) => ({
                        ...prevState,
                        isLogged: true,
                    }));
                    proPs.islogged();
                } else {
                    setState((prevState) => ({
                        ...prevState,
                        isOtpError: true,
                        loader: false,
                    }));
                }
            });
        } else {
            return false;
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault(); //prevent default
        requiredValid(state.userName, "userName");
        requiredValid(state.password, "password");
        // requiredValid(state.adminuser, "adminuser");
        if (state.showCaptcha) {
            requiredValid(state.captchavalue, "captchavalue");
        }

        const service = new LoginService();
        if (formValid(state.FormError)) {
            setState((prevState) => ({
                ...prevState,
                loader: true,
            }));
            service.getApi(state.adminuser).then(async (res) => {
                if (res.status) {
                    let cridential = {
                        username: state.userName,
                        password: state.password,
                    };
                    setState((prevState) => ({
                        ...prevState,
                        loader: false,
                    }));
                    await localStorage.setItem("apiKey", res.data.key);
                    UserStore.apiKey = res.key;
                    await service.LoginUser(cridential).then((res) => {
                        if (res.status) {
                            localStorage.setItem("adminname", state.adminuser);
                            localStorage.setItem("username", state.userName);
                            localStorage.setItem("timeout", false);
                            if (res.data.twofa_status) {
                                // resetform();
                                let qrcode;
                                qrcode = res.data?.auth_qr_code.replace(
                                    /\\"/g,
                                    '"'
                                );
                                setState((prevState) => ({
                                    ...prevState,
                                    isLogged: false,
                                    twofa: true,
                                    auth_key: res.data.secret_key,
                                    qrCode: qrcode,
                                    showQr: res.data.show_qr,
                                    return_key: res.data.return_key,
                                }));
                            } else if (res.data.unapproved === true) {
                                setState((prevState) => ({
                                    ...prevState,
                                    unapproved: true,
                                    showCaptcha: false,
                                    isLogged: true,
                                }));
                                UserStore.key = res.data.access_token;
                                UserStore.isLoggedIn = true;
                                localStorage.setItem(
                                    "login",
                                    JSON.stringify({
                                        token: res.data.access_token,
                                    })
                                );
                                proPs.islogged();
                            }

                            // setState((prevState) => ({
                            //   ...prevState,
                            //   showCaptcha: false,
                            // }));
                            else {
                                resetform();
                                localStorage.setItem(
                                    "login",
                                    JSON.stringify({
                                        token: res.data.access_token,
                                    })
                                );
                                localStorage.setItem(
                                    "currency",
                                    res.data.currency.code
                                );
                                localStorage.setItem(
                                    "approved",
                                    res.data.approved
                                );
                                UserStore.key = res.data.access_token;
                                UserStore.isLoggedIn = true;
                                setState((prevState) => ({
                                    ...prevState,
                                    isLogged: true,
                                }));
                                proPs.islogged();
                            }
                            // let currencyData =  Curr.Currecy.find((obj)=>obj.code == res.data.currency.code);
                            // dispatch(changeCurr(currencyData.symbol_left,currencyData.value,currencyData.precision,currencyData.id))

                            localStorage.setItem("invalidlogin", 0);
                            // } else if (res.status.unapproved === true) {
                            //   setState((prevState) => ({
                            //     ...prevState,
                            //     isLogged: false,
                            //     unapproved: true,
                            //   }));
                            // } else {
                            if (res.data && res.data.code === 1055) {
                                let Counter = localStorage.getItem(
                                    "invalidlogin"
                                )
                                    ? parseInt(
                                          localStorage.getItem("invalidlogin")
                                      )
                                    : 1;
                                if (Counter >= 3) {
                                    setState((prevState) => ({
                                        ...prevState,
                                        isLoggedError: true,
                                        captcha: res.data.captcha,
                                        showCaptcha: true,
                                    }));
                                    // Counter = 0;
                                } else {
                                    Counter = Counter + 1;
                                }
                                localStorage.setItem("invalidlogin", Counter);
                            }
                            // setState((prevState) => ({
                            //   ...prevState,
                            //   isLoggedError: true,
                            // }));
                        } else {
                            setState((prevState) => ({
                                ...prevState,
                                isLoggedError: true,
                            }));
                        }
                    });
                } else {
                    setState((prevState) => ({
                        ...prevState,
                        loader: false,
                    }));

                    if (res.error.code === 1042) {
                        setState((prevState) => ({
                            ...prevState,
                            isLoggedError: true,
                        }));
                    } else if (res.error.code === 1001) {
                        setState((prevState) => ({
                            ...prevState,
                            isLoggedError: true,
                        }));
                    } else if (res.error.code == 1043) {
                        setState((prevState) => ({
                            ...prevState,
                            isLoggedError: true,
                        }));
                    }
                }
            });
        } else {
            return false;
        }
    };
    return (
        <>
            <div className="mb-2">
                <Helmet>
                    <title>{COMPANY_NAME}</title>
                </Helmet>
                {!state.unapproved && (proPs.isLogged || state.isLogged) ? (
                    <Redirect to="/dashboard" />
                ) : (
                    ""
                )}
                {state.unapproved && (proPs.isLogged || state.isLogged) ? (
                    <Redirect to="/unapproved/dashboard" />
                ) : (
                    ""
                )}
                {state.isLoggedError ? (
                    <Alert
                        dismissible
                        variant="danger"
                        onClose={handleAlertDismiss}
                    >
                        {t("Login.Error.LoginError")}
                    </Alert>
                ) : (
                    ""
                )}
                {state.isOtpError ? (
                    <Alert
                        dismissible
                        variant="danger"
                        onClose={handleAlertDismiss}
                    >
                        {t("Login.Error.OtpError")}
                    </Alert>
                ) : (
                    ""
                )}
                {!state.twofa && (
                    <form id="login-form">
                        {/* {!params.adminusername && (
              <Forms
                type="text"
                inputClass={`${currentTheme == "theme-dark" && `${style.dark_mode_textArea}`
                  }`}
                name="adminuser"
                change={onchange}
                error={state.FormError.adminuser}
                value={state.adminuser}
              />
            )} */}
                        {state.timeout != true && (
                            <Forms
                                type="text"
                                name="userName"
                                inputClass={`${
                                    currentTheme == "theme-dark" &&
                                    `${style.dark_mode_textArea}`
                                }`}
                                change={onchange}
                                error={state.FormError.userName}
                                value={state.userName}
                            />
                        )}
                        <Forms
                            type="password"
                            name="password"
                            inputClass={`${
                                currentTheme == "theme-dark" &&
                                `${style.dark_mode_textArea}`
                            }`}
                            change={onchange}
                            error={state.FormError.password}
                            value={state.password}
                        />
                        {state.showCaptcha && (
                            <>
                                <React.Fragment>
                                    <Form.Group
                                        style={{
                                            textAlign: "center",
                                            background: "aliceblue",
                                            padding: "10px",
                                        }}
                                    >
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            sitekey={
                                                process.env
                                                    .REACT_APP_RECAPTCHA_KEY
                                            }
                                        />
                                    </Form.Group>
                                </React.Fragment>
                            </>
                        )}

                        <div
                            className={`${
                                currentTheme == "theme-dark"
                                    ? `${style.dark_mode_ForgotSection}`
                                    : `${style.ForgotSection}`
                            }`}
                        >
                            <div>
                                <Link to="/forgotPassword">
                                    {t("Common.forgotPassword")}?
                                </Link>
                            </div>
                            <div className={style.admin_login_lnk}>
                                {/* <a href="http://admin.infinitemlmsoftware.com">Admin Login</a> */}
                                {/* <a href="https://backoffice.infinitemlmsoftware.com">Admin Login</a> */}
                                {/* <a href="http://167.235.248.102:1003">Admin Login</a> */}
                            </div>
                        </div>

                        <LoginButton
                            submit={onSubmit}
                            disabled={state.btnDisabled}
                            {...state}
                        />
                        {/* <div className={`${currentTheme == "theme-dark"
                ? `${style.dark_mode_lg_login_btn_btm}`
                : `${style.lg_login_btn_btm}`
              }`}>
              Don't have an account?{" "}
              <a href="https://infinitemlmsoftware.com/register.php">
                Signup now
              </a>
            </div> */}
                    </form>
                )}
                {state.twofa && (
                    <form id="login-form">
                        {/* {process.env.REACT_APP_STATUS === "DEMO" ||
              (!params.adminusername && (
                <Forms
                  type="text"
                  name="adminuser"
                  change={onchange}
                  error={state.FormError.adminuser}
                  value={state.adminuser}
                />
              ))} */}
                        <div>{t("Common.enter_otp")}</div>
                        {state.showQr && (
                            <div>
                                <div>{t("Common.scan_image")}</div>
                                <div style={{ textAlign: "center" }}>
                                    <div>{t("Common.scan_qr")}</div>
                                    {/* <div>{t('Common.Scan this QR code image on google authenticator application and enter the OPT code to login.')}</div>
      <div>{t('Common.Scan QR to get OTP')}</div> */}

                                    <img src={state.qrCode} alt="" />
                                </div>

                                {/* <div>Authentication Key : {state.auth_key}</div>
                       <div>Store your secret code safely</div> */}
                            </div>
                        )}
                        <Forms
                            type="number"
                            name="otp"
                            change={onchange}
                            error={state.FormError.otp}
                            value={state.otp}
                        />
                        <div className={style.ForgotSection}>
                            <div>
                                <Link to="/googleAuth">
                                    {t("Common.google_auth")}?
                                </Link>
                            </div>
                        </div>
                        <LoginButton
                            submit={onOtpSubmit}
                            disabled={state.btnDisabled}
                            {...state}
                        />
                    </form>
                )}
            </div>
        </>
    );
}
export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))
);
