import React from "react";
import { useTranslation } from "react-i18next";
import style from "./profile.module.scss";
import { Button } from "react-bootstrap";

function Buttons(props) {
  const { t } = useTranslation();
  return (
    <div>
      <Button
        className={`${style.Button} ${style.update}`}
        onClick={props.submitHandler}
        disabled={props.submitDisable}
      >
        {t("Button.update")}
      </Button>
      <Button
        className={`${style.Button} ${style.cancel}`}
        variant="light"
        style={{ marginLeft: "5px" }}
        onClick={props.cancel}
      >
        {t("Button.cancel")}
      </Button>
    </div>
  );
}

export default Buttons;
