import React from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import companyName from "../../../store/mobxStore/companyName";
import PageTitle from "../../components/common/pageTitle";
import { useTranslation } from "react-i18next";
import ViewTutorialContent from "../../components/AcademicTutorials/ViewTutorialContent";

function ViewTutorial() {
  const { t } = useTranslation();
  return (
    <div className="h-100 vimeo">
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.tutorial")}
        </title>
      </Helmet>
      <PageTitle title="tutorial" buttonOn={false} />
      <ViewTutorialContent />
    </div>
  );
}

export default ViewTutorial;
