import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import AcademyService from "../../../service/Academy/Academy";
import Spinner from "../../shared/Spinner";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Nophoto from "../../../assets/images/carousel/banner_1.jpg";

import style from "./academic.module.scss";
import nodata from "../../../assets/images/nophoto/no-datas-found.png";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
// import noVideo from "../../../assets/images/novideo.png";
function ViewTutorialContent() {
    const service = new AcademyService();
    const { id } = useParams();
    const [Loading, setLoading] = useState(true);
    const [state, setstate] = useState({
        data: [],
    });
    useEffect(() => {
        getFolders();
    }, []);
    const getFolders = () => {
        service.getFolders().then((res) => {
            console.log(res);
            if (res.status) {
                setLoading(false);
                setstate((prev) => ({
                    ...prev,
                    data: res.data,
                }));
            } else {
                setLoading(true);
            }
        });
    };
    const { t } = useTranslation();
    const history = useHistory();

    const download = (e) => {
        var fn = e.match(/([^/])+/g);
        fn = fn[fn.length - 1];
        fetch(e).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = fn;
                a.click();
            });
        });
    };
    const viewCourse = (id) => {
        history.push(`/view_courses/${id}`);
    };

    return (
        <>
            {Loading ? (
                <Spinner />
            ) : (
                <div className="p-4">
                    {state?.data?.length !== 0 && (
                        <Row>
                            {state.data.map((item, index) => (
                                <Col key={index} md={4} lg={4}>
                                    <Card>
                                        <Card.Body>
                                            <Card.Text>{item.name}</Card.Text>
                                            <Card.Img src={Nophoto} />
                                        </Card.Body>
                                        <Card.Footer>
                                            <Button
                                                variant="info"
                                                className="{`${style.btnAddon} ${style.btnAddonInfo}`}"
                                                onClick={() =>
                                                    viewCourse(item.id)
                                                }
                                            >
                                                {t("View Course")}
                                            </Button>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    )}
                    {state?.data?.length === 0 && (
                        <div className={`${style.NodataImage}`}>
                            <center>
                                <img
                                    src={nodata}
                                    alt="nodata"
                                    style={{ width: "50%" }}
                                />
                            </center>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default ViewTutorialContent;
