import React, { useEffect } from "react";
import style from "./replica.module.scss";
import { Row, Col, Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer(props) {
    const { t } = useTranslation();
    return (
        <footer className="footer_sec">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 footer_lft">
                        2023 © {props.data.header_data?.company_name}
                    </div>
                    <div className="col-md-6 fooer_link">
                        <Link to={`/privacy-policy/${props.user_name}`}>
                            {t("Replica.privacy")}
                        </Link>
                        <Link to={`/terms-conditions/${props.user_name}`}>
                            {t("Replica.terms")}
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
